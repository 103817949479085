// import { Navigate } from 'react-router-dom';
// import { useAuth } from "../../contexts/AuthContext";

// const ProtectedRoute = ({ children }) => {
//     const { isLoggedIn } = useAuth();  
//     // return !isLoggedIn ? <>{ children }</> : <Navigate to="/" />;
//     // var val = 
//     console.log(!isLoggedIn);
//     if(!isLoggedIn){
//       return <Navigate to="/"></Navigate>
//     }
//     console.log(children);
//     return <>{children}</>;
//   };
  
//   export default ProtectedRoute;




// components/util/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const ProtectedRoute = ({ children }) => {
  const { isLoggedIn, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>; 
  }

  if (!isLoggedIn) {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
