// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success-popup {
  position: fixed;
  top: 20%;
  right: 0%;
  transform: translate(0%, -50%);
  z-index: 1000;
  background-color: #ee3a24;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: fadeInOut 2s ease-in-out;
}
.success-popup .popup-content {
  color: white;
  text-align: center;
  font-size: 16px;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/popup/SuccessPopup.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,QAAA;EACA,SAAA;EACA,8BAAA;EACA,aAAA;EACA,yBAAA;EACA,aAAA;EACA,kBAAA;EACA,wCAAA;EACA,mCAAA;AACJ;AACI;EACE,YAAA;EACA,kBAAA;EACA,eAAA;AACN;;AAGE;EACE;IACE,UAAA;EAAJ;EAEE;IACE,UAAA;EAAJ;EAEE;IACE,UAAA;EAAJ;EAEE;IACE,UAAA;EAAJ;AACF","sourcesContent":[".success-popup {\n    position: fixed;\n    top: 20%;\n    right: 0%;\n    transform: translate(0%, -50%);\n    z-index: 1000;\n    background-color: #ee3a24;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n    animation: fadeInOut 2s ease-in-out;\n  \n    .popup-content {\n      color: white;\n      text-align: center;\n      font-size: 16px;\n    }\n  }\n  \n  @keyframes fadeInOut {\n    0% {\n      opacity: 0;\n    }\n    10% {\n      opacity: 1;\n    }\n    90% {\n      opacity: 1;\n    }\n    100% {\n      opacity: 0;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
