import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import editButton from "../../assests/png/edit.png";
import "./Payments.scss";
import { fetchData } from "../../config/service";
import ApiConfig from "../../config/ApiConfig";
import { useAuth } from "../../contexts/AuthContext";
import Spinner from "../loader/Spinner";
import SuccessPopup from "../popup/SuccessPopup";

const Payments = () => {
  const { token, brandID, logout } = useAuth();
  const [isDisabled, setDisabled] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [payment, setPayment] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const getEnvDetails = async () => {
      setLoading(true);
      const response = await fetchData(
        `${ApiConfig.brands}?page=1&limit=10&brand_id=${brandID}`,
        setLoading,
        "get",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (response.statusCode === 401 || response.statusCode === 500) {
        logout();
      }
      if (response.status === 200) {
        setPayment(response.data.data.rows[0].brand_env_selection);
      }
      setLoading(false);
    };

    getEnvDetails();
  }, [brandID, token, logout]);

  const validationSchema = Yup.object({
    paymentStatus: Yup.string().required("Required"),
    paymentFrequency: Yup.string().required("Required"),
    paymentDate: Yup.date().required("Required"),
    nextPaymentDate: Yup.date().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      paymentStatus: "",
      paymentFrequency: "",
      paymentDate: "",
      nextPaymentDate: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const temp = {
        payment_status: values.paymentStatus === "paid" ? 1 : 0,
        payment_date: values.paymentDate,
        next_payment_date: values.nextPaymentDate,
        payment_frequency: values.paymentFrequency,
      };
      setLoading(true);
      const response = await fetchData(
        `${ApiConfig.payments}/${brandID}`,
        setLoading,
        "post",
        temp,
        { authorization: `Bearer ${token}` }
      );
      setLoading(false);
      if (response.statusCode === 401 || response.statusCode === 500) {
        logout();
      } else if (response.status === 200) {
        setDisabled(true);
        setShowPopup(true);
      }
    },
  });

  const calculateNextPaymentDate = (paymentDate, paymentFrequency) => {
    if (!paymentDate || !paymentFrequency) return "";
    const date = new Date(paymentDate);
    const monthsToAdd = paymentFrequency === "6 months" ? 6 : 12;
    date.setMonth(date.getMonth() + monthsToAdd);
    return date.toISOString().split("T")[0]; 
  };

  useEffect(() => {
    if (payment) {
      formik.setValues({
        paymentStatus: payment.payment_status || "unpaid",
        paymentFrequency: payment.payment_frequency || "6 months",
        paymentDate: payment.payment_date || "",
        nextPaymentDate: payment.next_payment_date || "",
      });
    }
  }, [payment]);

  const handleEditClick = () => {
    setDisabled(false);
  };

  const handleCancelClick = () => {
    formik.setValues({
      paymentStatus: payment.payment_status || "unpaid",
      paymentFrequency: payment.payment_frequency || "6 months",
      paymentDate: payment.payment_date || "",
      nextPaymentDate: payment.next_payment_date || "",
    });
    setDisabled(true);
  };

  const handlePaymentDateChange = (e) => {
    const paymentDate = e.target.value;
    const nextPaymentDate = calculateNextPaymentDate(paymentDate, formik.values.paymentFrequency);
    formik.setFieldValue("paymentDate", paymentDate);
    formik.setFieldValue("nextPaymentDate", nextPaymentDate);
  };

  return (
    <div className="section-1 payments">
      <div className="edit-button" style={{ textAlign: "right", cursor: 'pointer' }} onClick={handleEditClick}>
        <img src={editButton} alt="edit_button" />
      </div>
      <div className="form">
        {isLoading ? (
          <div className="spinner-container">
            <Spinner />
          </div>
        ) : (
          payment && (
            <form onSubmit={formik.handleSubmit}>
              <div className="text-field">
                <div>
                  <label htmlFor="paymentStatus">Payment Status</label>
                  <select
                    id="paymentStatus"
                    name="paymentStatus"
                    onChange={formik.handleChange}
                    value={formik.values.paymentStatus}
                    disabled={isDisabled}
                  >
                    <option value="unpaid">Unpaid</option>
                    <option value="paid">Paid</option>
                  </select>
                  {formik.touched.paymentStatus && formik.errors.paymentStatus ? (
                    <div className="error-msg">{formik.errors.paymentStatus}</div>
                  ) : null}
                </div>

                <div>
                  <label htmlFor="paymentFrequency">Payment Frequency</label>
                  <select
                    id="paymentFrequency"
                    name="paymentFrequency"
                    onChange={formik.handleChange}
                    value={formik.values.paymentFrequency}
                    disabled={isDisabled}
                  >
                    <option value="6 months">6 Months</option>
                    <option value="12 months">12 Months</option>
                  </select>
                  {formik.touched.paymentFrequency && formik.errors.paymentFrequency ? (
                    <div className="error-msg">{formik.errors.paymentFrequency}</div>
                  ) : null}
                </div>
              </div>
              <div className="text-field">
                <div>
                  <label htmlFor="paymentDate">Payment Date</label>
                  <input
                    type="date"
                    id="paymentDate"
                    name="paymentDate"
                    onChange={handlePaymentDateChange}
                    value={formik.values.paymentDate}
                    disabled={isDisabled}
                  />
                  {formik.touched.paymentDate && formik.errors.paymentDate ? (
                    <div className="error-msg">{formik.errors.paymentDate}</div>
                  ) : null}
                </div>

                <div>
                  <label htmlFor="nextPaymentDate">Next Payment Date</label>
                  <input
                    type="date"
                    id="nextPaymentDate"
                    name="nextPaymentDate"
                    onChange={formik.handleChange}
                    value={formik.values.nextPaymentDate}
                    disabled={isDisabled}
                  />
                  {formik.touched.nextPaymentDate && formik.errors.nextPaymentDate ? (
                    <div className="error-msg">{formik.errors.nextPaymentDate}</div>
                  ) : null}
                </div>
              </div>

              <div className={`buttons ${isDisabled ? 'hide' : ''}`}>
                <button type="button" className="button2" onClick={handleCancelClick}>
                  Cancel
                </button>
                <button type="submit" className="button2">
                  Save Changes
                </button>
              </div>
            </form>
          )
        )}
      </div>
      {showPopup && <SuccessPopup message="Form submitted successfully!" onClose={() => setShowPopup(false)} />}
    </div>
  );
};

export default Payments;
