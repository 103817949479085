import Login from './components/Login';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.scss';
import ProtectedRoute from './components/util/ProtectedRoute';
import Dashboad from './components/dashboad/Dashboad';
import Home from './components/dashboad/dashboardComponents/Home';
import DashboardLogin from './components/dashboad/dashboardLogin/DashboardLogin';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Login/>}/>
          <Route
            path="dashboard"
            element={<ProtectedRoute><Dashboad/></ProtectedRoute>}
          >
            <Route path='' element={<Home/>} />
            <Route path=":brandId" element={<DashboardLogin/>}/>
            {/* <Route path='db' element={<DashboardLogin/>} /> */}
            
          </Route>
        </Route>
        <Route path="*" element={<Login />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
