import { NavLink} from "react-router-dom";
import { ReactComponent as HomeIcon } from "../../assests/svg/House_01.svg";

const SideNav = () => {
  return (
    <div className='sidenav'>
      <ul>
        <li>
            <NavLink
                exact="true"
                to="/dashboard"
            >
                <HomeIcon /> <span className="link_name">Home</span>{" "}
            </NavLink>
        </li>
      </ul>
    </div>
  )
}

export default SideNav
