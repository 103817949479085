// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header.sigupHeader nav {
  width: 100%;
  max-width: 100%;
  padding: 1vw 2vw 0.5vw;
}
header.sigupHeader nav ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header.sigupHeader nav ul svg circle {
  display: none;
}
header.sigupHeader nav ul li.info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.2vw;
}
header.sigupHeader img.logo {
  max-width: 80%;
}
header.sigupHeader .dropdown-menu {
  position: relative;
  cursor: pointer;
}
header.sigupHeader .dropdown-menu .dropdown {
  display: none;
  position: absolute;
}
header.sigupHeader .dropdown-menu .dropdown.show {
  bottom: -15px;
  transform: translate(-90%, 100%);
  display: unset;
  width: 8.65vw;
  height: auto;
  padding: 1vw 1.11vw;
  background-color: #fff;
  box-shadow: 0 0 3px gainsboro;
  border-radius: 3px;
}
header.sigupHeader .dropdown-menu .dropdown.show span {
  font-weight: 400;
  line-height: 1.25vw;
  color: #3a3a3a;
  font-size: 1vw;
}`, "",{"version":3,"sources":["webpack://./src/components/header/Header.scss"],"names":[],"mappings":"AACI;EACI,WAAA;EACA,eAAA;EACA,sBAAA;AAAR;AAEQ;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;AAAZ;AAEgB;EACI,aAAA;AAApB;AAIY;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,UAAA;AAFhB;AAOI;EACI,cAAA;AALR;AAOI;EACI,kBAAA;EACA,eAAA;AALR;AAMQ;EACI,aAAA;EACA,kBAAA;AAJZ;AAKY;EACI,aAAA;EACA,gCAAA;EACA,cAAA;EACA,aAAA;EACA,YAAA;EACA,mBAAA;EACA,sBAAA;EACA,6BAAA;EACA,kBAAA;AAHhB;AAIgB;EACI,gBAAA;EACA,mBAAA;EACA,cAAA;EACA,cAAA;AAFpB","sourcesContent":["header.sigupHeader{\n    nav {\n        width: 100%;\n        max-width: 100%;\n        padding: 1vw 2vw .5vw;\n\n        ul{\n            display: flex;\n            align-items: center;\n            justify-content: space-between;\n            svg{\n                circle{\n                    display: none;\n                }\n            }\n\n            li.info{\n                display: flex;\n                align-items: center;\n                justify-content: center;\n                gap: 1.2vw;\n            }\n        }\n    }\n\n    img.logo{\n        max-width: 80%;\n    }\n    .dropdown-menu{\n        position: relative;\n        cursor: pointer;\n        .dropdown{\n            display: none;\n            position: absolute;\n            &.show{\n                bottom: -15px;\n                transform: translate(-90%, 100%);\n                display: unset;\n                width: 8.65vw;\n                height: auto;\n                padding: 1vw 1.11vw;\n                background-color: #fff;\n                box-shadow: 0 0 3px gainsboro;\n                border-radius: 3px;\n                span{\n                    font-weight: 400;\n                    line-height: 1.25vw;\n                    color: #3a3a3a;\n                    font-size: 1vw;\n                }\n            }\n        }\n    \n    }\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
