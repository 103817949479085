import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import React,{useState, useEffect} from "react";
import Logo from "../../assests/svg/logo-header.svg";
import Logo2 from "../../assests/svg/logo-inner-header.svg";
import "./Header.scss";
import Avatar from "react-avatar";
import { ReactComponent as Bell } from "../../assests/svg/Bell.svg";
import { useAuth } from "../../contexts/AuthContext";
import { ReactComponent as DownArrowIcon } from "../../assests/svg/down-arrow.svg";
const Header = ({ variation }) => {
  const { user, logout } = useAuth();
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (showDropdown && !event.target.closest(".dropdown-menu")) {
        setShowDropdown(false);
      }
    }
    window.addEventListener("click", handleClickOutside);
    return () => window.removeEventListener("click", handleClickOutside);
  }, [showDropdown]);

  return (
    <header className="sigupHeader">
      <nav>
        <ul>
          <li>
            <Tooltip title="Back to Website">
              <Link to="https://www.onewayx.com/" target="_blank">
                <img
                  src={variation === "1" ? Logo : Logo2}
                  alt="logo"
                  className="logo"
                />
              </Link>
            </Tooltip>
          </li>
          {variation !== "1" && (
            <li className="info">
              <Bell />
              <span style={{display:'flex' ,alignItems:'center' , justifyContent: 'center' , gap:'.2vw', cursor:'pointer'}}>
                <Avatar name={user} size={31} round="0.17316vw"
                onClick={(event) => {
                  event.stopPropagation();
                  setShowDropdown(!showDropdown);
                }}
                />
                <span
                  className="dropdown-menu"
                  style={{cursor:'pointer'}}
                  onClick={() => {
                    setShowDropdown(!showDropdown);
                  }}
                >
                  <span >
                    <DownArrowIcon />
                  </span>
                  <ul className={showDropdown ? "dropdown show" : "dropdown"}>
                    <li onClick={() => logout()}>
                      <span>Logout</span>
                    </li>
                  </ul>
                </span>
              </span>
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
