import axios from 'axios';
import { BASE_URL } from './ApiConfig';

const HEADER = { "Content-Type": "application/json" };
const HEADER2 = { "Content-Type": "multipart/form-data" };

async function fetchData(endpoint, setLoading, method = 'GET', payload = {}, header = null, signal = null) {
  try {
    let response;
    switch (method.toUpperCase()) {
      case 'GET':
        response = await axios.get(`${BASE_URL}${endpoint}`, { headers: { ...HEADER, ...header }, signal });
        break;
      case 'POST':
        response = await axios.post(`${BASE_URL}${endpoint}`, payload, { headers: { ...HEADER, ...header }, signal });
        break;
      case 'MEDIA':
        response = await axios.post(`${BASE_URL}${endpoint}`, payload, { headers: { ...HEADER2, ...header }, signal });
        break;
      case 'PUT':
        response = await axios.put(`${BASE_URL}${endpoint}`, payload, { headers: { ...HEADER, ...header }, signal });
        break;
      case 'DELETE':
        response = await axios.delete(`${BASE_URL}${endpoint}`, { headers: { ...HEADER, ...header }, signal });
        break;
      default:
        throw new Error(`Unsupported HTTP method: ${method}`);
    }

    setLoading(false);
    return response;
  } catch (error) {
    setLoading(false);
    return error.response.data;
  }
}

export { fetchData };
