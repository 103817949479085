import React, { createContext, useReducer, useContext, useEffect, useState } from 'react';

const initialState = {
  isLoggedIn: false,
  user: null,
  token: null,
  brandID: null,
  setBrandID: () => {},
  login: () => {},
  logout: () => {}
};

const AuthContext = createContext(initialState);

const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('user', action.payload.user);
      localStorage.setItem('tokenTime', Date.now().toString());
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload.user,
        token: action.payload.token,
        brandID: null,
      };
    case 'LOGOUT':
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('tokenTime');
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        token: null,
        brandID: null,
      };
    case 'BRAND_DATA':
      return {
        ...state,
        brandID: action.payload.brand
      };
    default:
      return state;
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    const tokenTime = localStorage.getItem('tokenTime');
    const now = Date.now();

    if (token && tokenTime) {
      const tokenAge = now - parseInt(tokenTime, 10);
      const maxTokenAge = 24 * 60 * 60 * 1000;

      if (tokenAge > maxTokenAge) {
        dispatch({ type: 'LOGOUT' });
      } else {
        dispatch({ type: 'LOGIN_SUCCESS', payload: { token, user } });
      }
    }

    setLoading(false);
  }, []);

  const login = (userData) => {
    const { token, user } = userData;
    dispatch({ type: 'LOGIN_SUCCESS', payload: { user, token } });
  };

  const logout = () => {
    dispatch({ type: 'LOGOUT' });
  };

  const setBrandID = (brand) => {
    dispatch({ type: 'BRAND_DATA', payload: { brand } });
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: state.isLoggedIn,
        user: state.user,
        token: state.token,
        brandID: state.brandID,
        login,
        logout,
        setBrandID,
        loading
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
