import Header from '../header/Header';
import SideNav from './SideNav';
import { Outlet } from 'react-router-dom';
import './Dashboard.scss';
import { useState, useEffect } from "react";
import Spinner from '../loader/Spinner';

const Dashboad = ({children}) => {
    const [pageLoad, setPageLoad] = useState(true);
    useEffect(()=>{
        const timer = setTimeout(() => {
            setPageLoad(false);
          }, 500); 
      
          return () => clearTimeout(timer);
    },[])

    if(pageLoad){
        return(
            <div className='loading'>
                <Spinner/>
            </div>
        )
    }
  return (
    <>
      <Header variation='2'/>
      <div className='dashboard'>
        <SideNav/>
        <div className="main-content">{children}
        <Outlet/>
        </div>
      </div>
    </>
  )
}

export default Dashboad


// import { Outlet } from "react-router-dom";
// import DashboardLayout from "./DashboardLayout";
// import './Dashboard.scss';



//  <div className="dashboard-header">
// <Header variation={3}></Header>
// </div>
  
//    <div className="dashboard-layout">
  
//        <SideNav />
    
//        <div  className="home-section">
//        <div className="main-content">{children}</div>
//        </div>
   
//    </div> 
//     <Outlet />