import React,{useState, useEffect} from 'react'
import './Transaction.scss'
import { useAuth } from '../../contexts/AuthContext';
import { fetchData } from '../../config/service';
import ApiConfig from '../../config/ApiConfig';
import Spinner from '../loader/Spinner';

const Transaction = () => {
    const [isloading, setIsLoading] = useState(true);
    const [eventList,setEventList] = useState([]);
    const {token,brandID,logout} = useAuth();

    useEffect(()=>{
        const transaction = async () => {
            setIsLoading(true);
            const response = await fetchData(
                `${ApiConfig.eventLog}/${brandID}`,
                setIsLoading,
                "post",
                {
                    "page": 1,
                    "limit": 1000
                },
                { authorization: `Bearer ${token}` }
            );
            if (response.statusCode === 401 || response.statusCode === 500) {
                logout();
             } 
            else if (response.status === 200) {
                setEventList(response.data.data.rows);
                console.log(response.data.data.rows);
             }   
            setIsLoading(false);
        };
        transaction();
    },[brandID,token,logout])
  return (
    <div className='section-1 transaction'>
      <div className='transaction-table'>
        {isloading ? <div className='spinner-container' style={{display:'flex',alignItems:'center',justifyContent:'center',height:'30vh'}}><Spinner/></div> :
        <table>
            <thead>
                <tr>
                    <th>
                        <p>Event</p>
                    </th>
                    <th>
                        <p>User</p>
                    </th>
                    <th>
                        <p>Time</p>
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                  eventList.map((data,index)=>(
                 <tr key={index}>
                    <td>
                        <p>{data.event_name}</p>
                    </td>
                    <td>
                        <p>{data.event_email}</p>
                    </td>
                    <td>
                        <p>{data.created_at}</p>  
                    </td>
                 </tr>   
                  ))
                }
            </tbody>
        </table>
        }
      </div>
    </div>
  )
}

export default Transaction
