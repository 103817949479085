import { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {fetchData} from "../config/service";
import { useAuth } from '../contexts/AuthContext';
import Header from "./header/Header";
import ApiConfig from "../config/ApiConfig";
import EmailNotFound from "./emailNotFound/EmailNotFound";
import Spinner from "./loader/Spinner";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";

import { ReactComponent as Visibility } from "../assests/svg/Eye-show.svg";
import { ReactComponent as VisibilityOff } from "../assests/svg/Eye-hide.svg";
import './Login.scss';


const Login = () => {

  const { login, isLoggedIn} = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [openEmailNotFoundPopup, setOpenEmailNotFoundPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading,setLoading] = useState(false);

  const navigate = useNavigate();


  //function to make api call and obtain login token
  const handleLogin = async () => {
    setLoading(true);
    const resp = await fetchData(
      ApiConfig.signIn,
      setLoading,
      "post",
      formik.values,
      {}
    );
    if(resp){
      if (resp?.statusCode === 401 || resp?.statusCode === 500) {
        setOpenEmailNotFoundPopup(true);
        setErrorMessage(resp?.message);        
      }
      var name = resp.data.result.first_name+' '+resp.data.result.last_name;
      login({  token: resp.data.data,user: name });
    }
    setLoading(false);
    navigate('/dashboard');
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter valid Email")
        .matches(/@[^.]*\./, "Please enter valid Email")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: handleLogin,
  });

  //mui external style
  const style = {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#fff",
      "&.Mui-focused fieldset": {
        borderColor: "#EE3A24CC",
      },
    },
  };
  if(isLoggedIn){
    navigate('./dashboard');    
  }

  return (
    <>
    <div className="login-page">
      <Header variation='1'/>
      <div className={"login-section" + ( openEmailNotFoundPopup || loading  ? ' bg-blurred' : '')}>
        <div className="login-container">
          <h3 className="heading">Login</h3>
          <form onSubmit={formik.handleSubmit} noValidate>
            <div className="input-container">
              <TextField
                sx={style}
                className="my-input set-pwd"
                placeholder="Your Email*"
                type="email"
                variant="outlined"
                required
                name="email"
                id="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                sx={style}
                className="my-input set-pwd"
                placeholder="Password*"
                variant="outlined"
                required
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </div>
            {process.env.REACT_APP_RECAPTCHA_MODE === "true" && (
              <div className="dialog-recaptcha">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={(value) => setIsVerified(!!value)}
                />
              </div>
            )}
            <div className="dialog-submit-container">
              <button
                type="submit"
                disabled={
                  !isVerified && process.env.REACT_APP_RECAPTCHA_MODE === "true"
                }
                className="dialog-input-submit button2"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
      {openEmailNotFoundPopup && (
        <EmailNotFound errorMessage={errorMessage}
          openEmailNotFoundPopup={openEmailNotFoundPopup}
          setOpenEmailNotFoundPopup={setOpenEmailNotFoundPopup}
          close={() => setOpenEmailNotFoundPopup(false)}
        />
      )}
         {loading && <Spinner/>}
    </div> 
    </>
  );
};

export default Login;
