export const BASE_URL = `${process.env.REACT_APP_BASE_URL}/backend/api/`;

let ApiConfig = {
  signIn       : `admin/signin`,
  dashboard    : `admin/dashboard`,
  brands       : `admin/brands`,
  payments     : `admin/payment`,
  enableLogin  : `admin/enableLogin`,
  retainTrail  : `admin/retainTrail`,
  credential   : `admin/credential`,
  eventLog     : `admin/eventLog`,
  analytics    : `admin/analytics`,
  login        : `admin/login`,
  appSetting   : `common/appSetting`,
  updateSetting: `admin/updateSetting`,
};

export default ApiConfig;