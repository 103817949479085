import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import editButton from "../../assests/png/edit.png";
import "./Credential.scss";
import { useAuth } from "../../contexts/AuthContext";
import { fetchData } from "../../config/service";
import ApiConfig from "../../config/ApiConfig";
import Spinner from "../loader/Spinner";
import SuccessPopup from "../popup/SuccessPopup";

const Credential = () => {
  const { logout, token, brandID } = useAuth();
  const [isDisabled, setDisabled] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [credential, setCredential] =  useState(null);
  const [showPopup, setShowPopup] = useState(false); 

  useEffect(() => {
    const getEnvDetails = async () => {
      setLoading(true);
      const response = await fetchData(
        `${ApiConfig.brands}?page=1&limit=10&brand_id=${brandID}`,
        setLoading,
        "get",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (response.statusCode === 401 || response.statusCode === 500) {
        logout();
      }
      if (response.status === 200) {
        setCredential(response.data.data.rows[0].brand_platform);
      }
      setLoading(false);
    };

    getEnvDetails();
  }, [brandID, token, logout]);

  const validationSchema = Yup.object({
    storeUrl: Yup.string().required("Required"),
    adminToken: Yup.string().required("Required"),
    accessToken: Yup.string().required("Required"),
    apiKey: Yup.string().required("Required"),
    apiPassword: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      storeUrl: "",
      adminToken:"",
      accessToken:"",
      apiKey:"",
      apiPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const response = await fetchData(
        `${ApiConfig.credential}/${brandID}`,
        setLoading,
        "post",
        {
          api_key: values.apiKey,
          api_password: values.apiPassword,
          access_token: values.accessToken,
          store_name: values.storeUrl,
          admin_token: values.adminToken,
        },
        { authorization: `Bearer ${token}` }
      );
      setLoading(false);
      if (response.statusCode === 401 || response.statusCode === 500) {
        logout();
      } else {
        setDisabled(true);
        setShowPopup(true);
      }
    },
  });

  useEffect(() => {
    if (credential) {
      formik.setValues({
        storeUrl: credential?.store_name || "",
        adminToken: credential?.admin_token || "",
        accessToken: credential?.access_token || "",
        apiKey:credential?.api_key || "",
        apiPassword:credential?.api_password || "",
      });
    }
  }, [credential]);

  const handleEditClick = () => {
    setDisabled(false);
  };

  const handleCancelClick = () => {
    setDisabled(true);
    formik.setValues({
      storeUrl: credential?.store_name || "",
      adminToken: credential?.admin_token || "",
      accessToken: credential?.access_token || "",
      apiKey:credential?.api_key || "",
      apiPassword:credential?.api_password || "",
    });
  };

  return (
    <div className="section-1 credential">
      <div className="edit-button" style={{ textAlign: "right", cursor: 'pointer' }} onClick={handleEditClick}>
        <img src={editButton} alt="edit_button" />
      </div>
      {isLoading ? (
        <div className='spinner-container'>
          <Spinner />
        </div>
      ) : (
        <div className="form">
          <form onSubmit={formik.handleSubmit}>
            <div className="text-field">
              <div>
                <label htmlFor="storeUrl">Store URL</label>
                <input
                  type="text"
                  id="storeUrl"
                  name="storeUrl"
                  onChange={formik.handleChange}
                  value={formik.values.storeUrl}
                  disabled={isDisabled}
                />
                {formik.touched.storeUrl && formik.errors.storeUrl ? (
                  <div className="error-msg">{formik.errors.storeUrl}</div>
                ) : null}
              </div>

              <div>
                <label htmlFor="adminToken">Admin Token</label>
                <input
                  type="text"
                  id="adminToken"
                  name="adminToken"
                  onChange={formik.handleChange}
                  value={formik.values.adminToken}
                  disabled={isDisabled}
                />
                {formik.touched.adminToken && formik.errors.adminToken ? (
                  <div className="error-msg">{formik.errors.adminToken}</div>
                ) : null}
              </div>
            </div>
            <div className="text-field">
              <div>
                <label htmlFor="accessToken">Access Token</label>
                <input
                  type="text"
                  id="accessToken"
                  name="accessToken"
                  onChange={formik.handleChange}
                  value={formik.values.accessToken}
                  disabled={isDisabled}
                />
                {formik.touched.accessToken && formik.errors.accessToken ? (
                  <div className="error-msg">{formik.errors.accessToken}</div>
                ) : null}
              </div>

              <div>
                <label htmlFor="apiKey">API Key</label>
                <input
                  type="text"
                  id="apiKey"
                  name="apiKey"
                  onChange={formik.handleChange}
                  value={formik.values.apiKey}
                  disabled={isDisabled}
                />
                {formik.touched.apiKey && formik.errors.apiKey ? (
                  <div className="error-msg">{formik.errors.apiKey}</div>
                ) : null}
              </div>
            </div>
            <div className="text-field">
              <div>
                <label htmlFor="apiPassword">API Password</label>
                <input
                  type="password"
                  id="apiPassword"
                  name="apiPassword"
                  onChange={formik.handleChange}
                  value={formik.values.apiPassword}
                  disabled={isDisabled}
                />
                {formik.touched.apiPassword && formik.errors.apiPassword ? (
                  <div className="error-msg">{formik.errors.apiPassword}</div>
                ) : null}
              </div>
            </div>
            {!isDisabled && (
              <div className="buttons">
                <button type="button" className="button2" onClick={handleCancelClick}>
                  Cancel
                </button>
                <button type="submit" className="button2">
                  Save Changes
                </button>
              </div>
            )}
          </form>
        </div>
      )}
      {showPopup && <SuccessPopup message="Form submitted successfully!" onClose={() => setShowPopup(false)} />}
    </div>
  );
};

export default Credential;