import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../contexts/AuthContext";
import { fetchData } from "../../config/service";
import ApiConfig from "../../config/ApiConfig";
import Spinner from "../loader/Spinner";
import { useDropzone } from "react-dropzone";
import editButton from "../../assests/png/edit.png";
import upload from '../../assests/png/upload.png';
import "./Analytic.scss";
import tick from '../../assests/png/tick.png';
import SuccessPopup from "../popup/SuccessPopup";

const Analytic = () => {
  const { logout, token, brandID } = useAuth();
  const [isDisabled, setDisabled] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [analytics, setAnalytics] = useState(null);
  const [filePresent, setFilePresent] = useState(false);
  const [showPopup, setShowPopup] = useState(false); 

  // Fetch initial data
  useEffect(() => {
    const getEnvDetails = async () => {
      setLoading(true);
      const response = await fetchData(
        `${ApiConfig.brands}?page=1&limit=10&brand_id=${brandID}`,
        setLoading,
        "get",
        {},
        { authorization: `Bearer ${token}` }
      );

      if (response.statusCode === 401 || response.statusCode === 500) {
        logout();
      } else if (response.status === 200) {
        setAnalytics(response.data.data.rows[0].brand_analytics_master);
        if(response.data.data.rows[0].brand_analytics_master?.private_content!== null){
          setFilePresent(!!response.data?.data?.rows[0]?.brand_analytics_master?.private_content);
        }
        
      }
      setLoading(false);
    };

    getEnvDetails();
  }, [brandID, token, logout]);

  const formik = useFormik({
    initialValues: {
      propertyID: analytics ? analytics.property_id : '',
      analyticsCredentials: null,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      propertyID: Yup.string().required("Required"),
      analyticsCredentials: Yup.mixed()
        .required("Required")
        .test("fileSize", "File too large", (value) => value && value.size <= 5 * 1024 * 1024)
        .test("fileType", "Invalid file type", (value) => value && value.type === "application/json"),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("propertyID", values.propertyID);
      if (values.analyticsCredentials) {
        formData.append("analyticsCredentials", values.analyticsCredentials);
      }
      console.log(formData);

          let temp = {
      "property_id": values.propertyID,
      "private_content": values.analyticsCredentials
    }
      setLoading(true);
      const response = await fetchData(
        `${ApiConfig.analytics}/${brandID}`,
        setLoading,
        "post",
        temp,
        { authorization: `Bearer ${token}` }
      );

      setLoading(false);
      if (response.statusCode === 401 || response.statusCode === 500) {
        logout();
      } else {
        setDisabled(true);
        setShowPopup(true);
      }
    },
  });

  useEffect(() => {
    if (analytics) {
      formik.setValues({
        propertyID: analytics.property_id || '',
        analyticsCredentials: null,
      });
    }
  }, [analytics]);

  const cancelHandler = () => {
    setDisabled(true);
    formik.setValues({
      propertyID: analytics.property_id || '',
      analyticsCredentials: null,
    });
  };

  const Dropzone = ({ setFieldValue, currentFile }) => {
    const { getRootProps, getInputProps } = useDropzone({
      accept: "application/json",
      maxSize: 5 * 1024 * 1024,
      onDrop: (acceptedFiles) => {
        setFieldValue("analyticsCredentials", acceptedFiles[0]);
      },
    });

    return (
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <div className="dropdown-box">
          {currentFile ? (
            <h2>{currentFile.name}</h2>
          ) : (
            <div className="dropbox">
              <h2>Upload Credentials<img src={upload} alt="upload" /></h2>
              <p>Upload JSON file containing API credentials</p>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="section-1 analytic">
      <div className="edit-button" style={{ textAlign: "right", cursor: 'pointer' }} onClick={() => setDisabled(false)}>
        <img src={editButton} alt="edit_button" />
      </div>
      {isLoading ? (
        <div className='spinner-container'>
          <Spinner />
        </div>
      ) : (
        <div className="form">
          <form onSubmit={formik.handleSubmit}>
            <div className="text-field">
              <div>
                <label htmlFor="propertyID">Property ID</label>
                <input
                  type="text"
                  id="propertyID"
                  name="propertyID"
                  onChange={formik.handleChange}
                  value={formik.values.propertyID}
                  disabled={isDisabled}
                />
                {formik.touched.propertyID && formik.errors.propertyID ? (
                  <div className="error-msg">{formik.errors.propertyID}</div>
                ) : null}
              </div>

              <div>
                <label htmlFor="analyticsCredentials">
                  Analytics Credentials (JSON)
                  {filePresent && <img src={tick} alt="tick" style={{width:'1.91vw', height: '.91vw', objectFit:'contain'}}/>}
                </label>
                <Dropzone
                  setFieldValue={formik.setFieldValue}
                  currentFile={formik.values.analyticsCredentials}
                />
                {formik.touched.analyticsCredentials && formik.errors.analyticsCredentials ? (
                  <div className="error-msg">{formik.errors.analyticsCredentials}</div>
                ) : null}
              </div>
            </div>
            {!isDisabled && (
              <div className="buttons">
                <button type="button" className="button2" onClick={cancelHandler}>
                  Cancel
                </button>
                <button type="submit" className="button2">
                  Save Changes
                </button>
              </div>
            )}
          </form>
        </div>
      )}
       {showPopup && <SuccessPopup message="Form submitted successfully!" onClose={() => setShowPopup(false)} />}
    </div>
  );
};

export default Analytic;
