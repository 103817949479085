// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: #fff;
}

.dashboard {
  display: flex;
  min-height: 91.736725vh;
}
.dashboard .main-content {
  width: calc(100% - 17.16vw);
  background-color: #F7F1FF;
}
.dashboard .sidenav {
  width: 17.16vw;
  background: linear-gradient(191.14deg, #ee3a24 39.22%, #9747ff 65.37%);
  z-index: 100;
  position: relative;
}
.dashboard .sidenav::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: radial-gradient(100% 100% at 0% 0%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  z-index: -1;
}
.dashboard .sidenav li {
  margin: 1.11vw 1.24875vw 0.555vw;
}
.dashboard .sidenav li a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 1.3875vw;
  color: #fff;
  text-decoration: none;
  padding: 0.69375vw;
  box-sizing: unset;
  background-color: #fff;
  border-radius: 3px;
}
.dashboard .sidenav li a svg {
  margin: 0 0.555vw 0 0;
}
.dashboard .sidenav li a .link_name {
  font-size: 0.90243vw;
  transition: all 0.4s ease;
  color: #000;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboad/Dashboard.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;AACJ;;AACA;EACE,aAAA;EACA,uBAAA;AAEF;AADE;EACE,2BAAA;EACA,yBAAA;AAGJ;AADE;EACE,cAAA;EACA,sEAAA;EACA,YAAA;EACA,kBAAA;AAGJ;AADI;EACE,WAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,MAAA;EACA,yGAAA;EAKA,WAAA;AADN;AAGI;EACE,gCAAA;AADN;AAGM;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,gBAAA;EACA,WAAA;EACA,qBAAA;EACA,kBAAA;EACA,iBAAA;EACA,sBAAA;EACA,kBAAA;AADR;AAEQ;EACE,qBAAA;AAAV;AAEQ;EACE,oBAAA;EACA,yBAAA;EACA,WAAA;AAAV","sourcesContent":[".loading{\n    position: relative;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100vw;\n    height: 100vh;\n    background: #fff;\n}\n.dashboard {\n  display: flex;\n  min-height: 91.736725vh;\n  .main-content{\n    width: calc(100% - 17.16vw);\n    background-color: #F7F1FF;\n  }\n  .sidenav {\n    width: 17.16vw;\n    background: linear-gradient(191.14deg, #ee3a24 39.22%, #9747ff 65.37%);\n    z-index: 100;\n    position: relative;\n  \n    &::after {\n      content: \"\";\n      position: absolute;\n      width: 100%;\n      height: 100%;\n      top: 0;\n      background: radial-gradient(\n        100% 100% at 0% 0%,\n        rgba(255, 255, 255, 0.4) 0%,\n        rgba(255, 255, 255, 0) 100%\n      );\n      z-index: -1;\n    }\n    li {\n      margin: 1.11vw 1.24875vw 0.555vw;\n  \n      a {\n        display: flex;\n        align-items: center;\n        justify-content: flex-start;\n        height: 1.3875vw;\n        color: #fff;\n        text-decoration: none;\n        padding: 0.69375vw;\n        box-sizing: unset;\n        background-color: #fff;\n        border-radius: 3px;\n        svg {\n          margin: 0 0.555vw 0 0;\n        }\n        .link_name {\n          font-size: 0.90243vw;\n          transition: all 0.4s ease;\n          color: #000;\n        }\n      }\n    }\n  }\n  \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
